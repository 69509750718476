import Card from "components/card";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import logo from "assets/img/logo/logo.png";
import config from "utils/config";

export default function Qrcode({ bartenderName }) {
  const [QRCodeValue, setQRCodeValue] = useState("");
  const [searchText, setSearchText] = useState("");

  const generateQRCode = () => {
    setQRCodeValue(searchText);
  };

  return (
    <Card>
      <div className="mt-8 flex flex-col items-center justify-center">
        <div className="flex h-20 w-full justify-center">
          <h4 className="absolute top-0 left-[35px] right-[19px] mt-[32px] flex justify-between text-2xl font-bold text-navy-700 dark:text-white">
            <div className="flex flex-col justify-between">
              <img
                className="mt-2 mr-[-10px] h-[25px] w-[80px]"
                src={logo}
                alt="logo"
              />

            </div>
          </h4>
        </div>
        <h2 className="text-2xl font-bold text-navy-700 dark:text-white">
          {"Scan to join"}
        </h2>

        <div className="mt-4 flex items-center">
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Enter text for QR code"
            className="px-4 py-2 mr-2 w-64 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black-500"
          />
          <button
            onClick={generateQRCode}
            className="px-4 py-2 bg-black text-black rounded-md text-sm"
          >
            Generate QR
          </button>


        </div>
        {QRCodeValue && (
          <div className="mt-4">
            <QRCode size={200} fgColor="black" value={config?.INVITE_BASE_URL + QRCodeValue} />
          </div>
        )}
      </div>
    </Card>
  );
}
