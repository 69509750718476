import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "components/header/HeaderAuthDefault";
import httpService from "services/http-service";
import { useEffect, useState } from "react";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";

const mask = "+1 (999) 999-9999";
const maskGenerator = createDefaultMaskGenerator(mask);

export default function SignUp() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isRegular, setIsRegular] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [isAge, setIsAge] = useState(false);
  const [loading, setLoading] = useState(false);
  const followId = parseInt(window.location.hash.replace("#", ""));
  const [followName, setFollowName] = useState("");
  const [followLink, setFollowLink] = useState("");

  useEffect(() => {
    if (location.pathname.includes("regular")) {
      setIsRegular(true);
      if (followId.toString() !== "NaN") {
        localStorage.setItem("followId", followId);
        setFollowLink(followId);
        fetchBartenderName();
      }
    } else {
      setIsRegular(false);
      if (followId.toString() !== "NaN") {
        localStorage.setItem("followId", followId);
      }
    }
  }, [location.pathname]);

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (fullName && phoneNumber && isAge) {
      setLoading(true);
      const endpoint = isRegular ? "regulars/signup" : "/bartenders/signup";
      const data = isRegular
        ? {
            fullName: fullName,
            phoneNumber: `+1${phoneNumber}`,
            referredByBartenderId: followId,
          }
        : {
            fullName: fullName,
            phoneNumber: `+1${phoneNumber}`,
            referredByBartenderId: followId,
          };
      const response = await httpService("POST", endpoint, data);
      if (response.Error === "HttpException") {
        setLoading(false);
        toast.error(response.Message);
      } else {
        if (response) {
          setLoading(false);
          toast.success("Gliddy SMS code has been sent");
          localStorage.setItem("userDetails", JSON.stringify(response)); // save the user to local storage
          navigate("/auth/verify", {
            state: {
              isRegular: isRegular,
            },
          });
        }
      }
    }
  };

  const handleValidation = () => {
    if (fullName !== "" && phoneNumber.length === 10 && isAge === true) {
      return false;
    } else {
      return true;
    }
  };

  const fetchBartenderName = async () => {
    setLoading(true);
    const endpoint = `/bartenders/getBartenderName/${followId}`;
    const response = await httpService("GET", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      setLoading(false);
      setFollowName(response);
    }
  };

  return (
    <div className="flex pt-6 h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      <Loading loading={loading} loaderColor="#252424" />
      {/* Sign Up Section */}
      <div className="mt-[0vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <Header
          tagline={
            isRegular
              ? `Welcome in! ${followName && "You are following..."}`
              : "Go out, find your crowd"
          }
        />
        {followName && (
          <h4 className="mb-10 mt-[-30px] text-2xl font-bold text-navy-700 dark:text-white">
            {followName}
          </h4>
        )}

        <h4 className="mb-2.5 text-2xl font-bold text-navy-700 dark:text-white">
          Sign Up
        </h4>
        <p className="mb-1 ml-1 text-base dark:text-white">
          We'll send a confirmation code to this number to verify your account
        </p>

        {/* Name */}
        <InputField
          variant="auth"
          extra="mt-4"
          label=""
          placeholder="Full Name"
          value={fullName}
          onChange={(event) => setFullName(event.target.value)}
          id="email"
          type="text"
        />

        {/* Phone Number */}
        <MaskedInput
          className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
          placeholder="Phone Number"
          pattern="\d*"
          inputMode="numeric"
          onKeyDown={handleKeyDown}
          maskGenerator={maskGenerator}
          value={phoneNumber}
          onChange={(event) => setphoneNumber(`${event}`)}
        />

        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="mt-4 flex items-center">
            <Checkbox onChange={(event) => setIsAge(event.target.checked)} />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              I confirm I'm over 18 years old
            </p>
          </div>
        </div>
        <button
          disabled={handleValidation()}
          onClick={() => handleLogin()}
          className={
            !handleValidation()
              ? "linear mt-10 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              : "linear mt-10 w-full cursor-not-allowed rounded-xl bg-gray-500 py-[12px] text-base font-medium text-white opacity-50 transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          }
        >
          Send Verification Code
        </button>
        <h4 className="mt-5 items-center justify-center font-bold text-navy-700 dark:text-white">
          &nbsp; Already have an account?{" "}
          <button
            className="text-brand-500"
            onClick={() =>
              handleRouteClick(
                isRegular
                  ? `/auth/regular-sign-in/#${followLink}`
                  : "/auth/sign-in"
              )
            }
          >
            {" "}
            &nbsp; Sign In{" "}
          </button>
        </h4>
        <div className="signIn-notes mt-8">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            We'll send you a verification code by SMS. Standard rates may apply.
            By continuing, I agree to Gliddy's
          </span>
          <a
            href="https://meetgliddy.com/terms-of-service"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Terms of Service.
          </a>
          <span className=" ml-1 text-sm font-medium text-navy-700 dark:text-gray-600">
            Please see Gliddy's
          </span>
          <a
            href="https://meetgliddy.com/privacypolicy"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Privacy Notice
          </a>
          <span className=" ml-1 text-sm font-medium text-navy-700 dark:text-gray-600">
            regarding its privacy practies.
          </span>
        </div>
      </div>
    </div>
  );
}
