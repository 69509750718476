import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { MdOutlineSettings, MdAutorenew, MdArrowBackIos } from "react-icons/md";
import { toast } from "react-toastify";
import httpService from "services/http-service";
import { getLocalStorageValue } from "utils/utils";
import { getLocalStorageKey } from "utils/utils";
import Header from "components/header/HeaderAuthDefault";
import { IoMdHeartEmpty } from "react-icons/io";
import { MdOutlineClose, MdOutlineQrCodeScanner } from 'react-icons/md';
import Sheet from "react-modal-sheet";
import { FaInstagram, FaLinkedin, FaRegPaperPlane } from "react-icons/fa";
import user from "assets/img/icons/user.png";

import UserProfile from "./UserProfile";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Notifications from "./Notification";
import EventScan from "./Eventscan";

export default function Event({ ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [sponsors, setSponsors] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [vipUsers, setVipUsers] = useState([]);
  const [recommendedUsers, setRecommendedUsers] = useState([]);
  const [waveClicked, setWaveClicked] = useState(false);
  const [otherUsers, setOtherUsers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [eventName, seteventName] = useState('');
  const [showNotifications, setShowNotifications] = useState(false);
  const [showReactions, setShowReactions] = useState(false);
  const [reactionClicked, setReactionClicked] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [showEventScan, setShowEventScan] = useState(false);
  const eventId = getLocalStorageKey("eventId");
  const userId = getLocalStorageValue("userDetails", "userId");
  const [hasNotifications, setHasNotifications] = useState(false);
  const [matches, setMatches] = React.useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [chats, setChats] = React.useState([]);

  const openConfirmation = (message, callback) => {
    setConfirmationMessage(message);
    setShowConfirmation(true);
  };

  // Function to close confirmation popup
  const closeConfirmation = () => {
    setShowConfirmation(false);
  };

  // Function to handle confirmation action
  const handleConfirmation = () => {
    // Handle confirmation action
    // For example: callback();
    // Then close the confirmation popup
    closeConfirmation();
  };


  const handleQRCodeIconClick = () => {
    setShowEventScan(true);
  };

  const handleCloseEventScan = () => {
    setShowEventScan(false);
  };

  const handleClick = () => {
    if (!reactionClicked) {
      setShowReactions(true);
    }
  };
  const handleReactionClick = () => {
    setShowReactions(false);
    setReactionClicked(true);
  };
  const handleWaveIconClick = () => {
    if (!reactionClicked) {
      setShowReactions(false);
      setReactionClicked(false);
    }
  };

  // Inside the useEffect hook that fetches data
  useEffect(() => {
    populateData();
    const intervalId = setInterval(() => {
      populateData(); // Call populateData() every 90 seconds
    }, 90 * 1000); // 90 seconds in milliseconds

    return () => clearInterval(intervalId);
  }, []);

  // Inside the populateData function
  const populateData = async () => {
    setLoading(true);
    const userId = getLocalStorageValue("userDetails", "userId");

    const endpoint = `EventUserInteraction/GetMatchQueue?UserId=${userId}`;
    const response = await httpService("GET", endpoint);

    if (!response) {
      setLoading(false);
      toast.error('Something went wrong');
    } else {
      setLoading(false);
      setMatches(response?.matches || []);
      setChats(response?.chats || []);

      // Calculate hasNotifications based on whether there are matches or chats
      const hasNotifications = response.matches.length > 0 || response.chats.length > 0;

      // Update the local state
      setHasNotifications(hasNotifications);
    }
  };

  const handleWaveClick = () => {
    setWaveClicked(true);
    toast.success("We'll let them know you've waved.");
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {

    // Cleanup function

    // Add event listener to handle outside click
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    getAlEvents();

    const intervalId = setInterval(() => {
      getAlEvents(); // Call getAlEvents() every 90 seconds
    }, 90 * 1000); // 90 seconds in milliseconds


    return () => clearInterval(intervalId);
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false); // Close the dropdown if clicked outside of it
    }
  };

  const selectUserProfile = (eventData) => {
    setSelectedUser(eventData);
    localStorage.setItem('selectedUserId', eventData?.userId); // will remove
    setSelectedUserId(eventData?.userId)
    setIsOpen(true); // Open the sheet when a user is selected
  };

  const selectRecommendedUserProfile = (recommended) => {
    setSelectedUser(recommended);
    localStorage.setItem('selectedUserId', recommended.user.id); // will remove
    setSelectedUserId(recommended.user.id)
    setIsOpen(true); // Open the sheet when a user is selected
  };

  const closeUserProfile = () => {
    setSelectedUser(null);
    setIsOpen(false); // Close the sheet when user profile is closed
  };

  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  const handleOptionClick = async (option) => {
    // Handle click action based on the selected option
    if (option === "refresh") {
      getAlEvents();
    } else if (option === "settings") {
      // Navigate to edit profile page
      navigate('/auth/edit-profile', { state: { fromEventsPage: true } });
    } else if (option === "logout") {
      localStorage.clear();
      navigate('/auth/sign-in');
    }
  };


  useEffect(() => {
    getAlEvents();
  }, []);

  const getAlEvents = async () => {
    setLoading(true);


    if (!eventId) {
      navigate('/auth/home');
      return;
    }
    const endpoint = `/Event/GetEventPeople/${eventId}`;
    const response = await httpService("GET", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      updateEventData(response);
      setLoading(false);
      setShowDropdown(false);
    }
  };

  const updateEventData = (eventData) => {
    seteventName(eventData?.eventName);
    setSponsors(eventData?.sponsors);
    setHosts(eventData?.hosts);
    setVipUsers(eventData?.vipUsers);
    setOtherUsers(eventData?.otherUsers);
    setRecommendedUsers(eventData?.recommendedUsers)
  };



  // const selectUserProfile = (eventData) => {
  //   console.log(eventData);
  //   localStorage.setItem('selectedUserId', eventData?.userId);
  //   navigate('/auth/user-profile')
  // };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-50 bg-white">
        <div className="flex relative mt-3 ml-2 mb-2 mr-2">
          <Header className="sticky top-0 bg-white shadow-md border-b border-gray-200" />
          <div className="mt-2 mr-2 text-2xl transition duration-300 hover:text-gray-500 cursor-pointer" onClick={toggleNotifications}>
            {hasNotifications && <div className="absolute top-2 right-16 mr-1 h-3 w-3 bg-red-500 rounded-full"></div>}
            <IoMdHeartEmpty />
          </div>
          {/* Add QR code icon */}
          <div className="mt-2 mr-2 text-2xl transition duration-300 hover:text-gray-500 cursor-pointer">
            <MdOutlineQrCodeScanner onClick={handleQRCodeIconClick} />
          </div>
          {/* Dialog for EventScan */}
          <Dialog open={showEventScan} onClose={handleCloseEventScan} maxWidth="sm" fullWidth>
            <EventScan onClose={handleCloseEventScan} />
          </Dialog>
          {/* Full Page Popup for Notifications */}
          {showNotifications && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-8 h-full w-full max-h-screen overflow-y-auto">
                <button className="absolute top-0 right-0 p-2" onClick={toggleNotifications}>
                  <MdOutlineClose className="h-6 w-6" />
                </button>
                <Notifications setHasNotifications={setHasNotifications} />
              </div>
            </div>
          )}
          <div
            className="mt-2 mr-2 text-2xl transition duration-300 hover:text-gray-500 cursor-pointer"
            onClick={toggleDropdown}
          >
            <MdOutlineSettings />
          </div>

          {showDropdown && (
            <div ref={dropdownRef} className="absolute right-0 mt-10 w-48 bg-white rounded-md shadow-lg z-50">
              <div className="py-1">
                <button
                  onClick={getAlEvents}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-200"
                >
                  Refresh
                </button>
             {/* Commenting out for MVP

                <button
                  onClick={() => handleOptionClick("settings")}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-200"
                >
                  Edit profile
                </button>
          */}
                <button
                  onClick={() => handleOptionClick("logout")}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-200"
                >
                  Logout
                </button>
              </div>
            </div>
          )}
          {/*Floating Action Button*/} {/*<div className="fixed bottom-8 right-4">
            
            <button className="bg-brand-500 hover:bg-brand-700 text-white py-2 px-4 rounded-full shadow-md">
              +
            </button>    </div>

       */}
        </div>
        {/* Separation line */}
        <div className="border-t border-gray-200"></div>
      </div>

      <div className="flex overflow-y-auto hide-scrollbar h-full w-full justify-center px-7 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
        <Loading loading={loading} loaderColor="#252424" />
        <div className="mt-14 w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <div>
            <h4 className="text-event-name-700 mb-5 mt-5">
              {eventName}
            </h4>
          </div>

          {/* Sponsors */}
          <h6 className="mb-1.5 text-1xl text-event-footer">Sponsors</h6>
          {sponsors.length > 0 ? (
            <div className="flex items-center justify-between text-2xl text-name-700 dark:text-white overflow-x-auto hide-scrollbar">
              {sponsors.map((sponsor) => (
                <div onClick={() => handleLinkClick(sponsor.url)}>
                  <div className="rounded-full overflow-hidden h-16 w-16 border-2 border-black mr-8">
                    <img
                      className="h-26 w-26 object-cover"
                      src={sponsor?.photo}
                      alt="logo" />
                  </div>
                  <h6 className="mb-.5 text-attendee-name dark:text-white flex items-center justify-center mr-8">
                    {sponsor.displayName}
                  </h6>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-attendee-name text-gray-500 flex justify-center">No sponsors, see host to become one.</p>
          )}


          {/* Hosts */}
          <h6 className="mb-1.5 ml-1.5 text-1xl mt-7 text-event-footer">Hosts</h6>
          {hosts.length > 0 ? (
            <div className="flex items-center justify-between text-2xl text-name-700 dark:text-white overflow-x-auto hide-scrollbar">
              {hosts.map((host) => (
                <div className="item" onClick={() => selectUserProfile(host)} key={host.id}>
                  <div className="rounded-full overflow-hidden h-16 w-16 border-2 border-black ml-6">
                    <img
                      className="h-26 w-26 object-cover"
                      src={host.user?.photoUrl}
                      alt="logo" />
                  </div>
                  <h6 className="mb-.5 mr-10 text-attendee-name dark:text-white flex items-center justify-center">
                    {host.user.firstName}
                  </h6>
                  {host?.makeFriends && '🎉'}
                  {host?.network && "🤝"}
                  {host?.date && "❤️"}

                  <button className="bg-white-gradient text-gray-500 w-host rounded-1 border-opacity-25 text-host font-normal">
                    {host.hostRole}
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-attendee-name text-gray-500 flex justify-center mt-4">No hosts, see host to become one.</p>
          )}

          {/* VIPs */}
          <h6 className="mb-1.5 ml-1.5 text-1xl mt-7 text-event-footer">VIPs</h6>
          {vipUsers.length > 0 ? (
            <div className="flex items-center justify-between text-2xl text-name-700 dark:text-white overflow-x-auto hide-scrollbar">
              {vipUsers.map((vip) => (
                <div className="item" onClick={() => selectUserProfile(vip)} key={vip.id}>
                  <div className="rounded-full overflow-hidden h-16 w-16 border-2 border-black ml-6">
                    <img
                      className="h-26 w-26 object-cover"
                      src={vip.user.photoUrl}
                      alt="logo" />
                  </div>
                  <h6 className="mb-.5 mr-10 text-attendee-name dark:text-white flex items-center justify-center">
                    {vip.user.firstName}
                  </h6>
                  {vip?.makeFriends && '🎉'}
                  {vip?.network && "🤝"}
                  {vip?.date && "❤️"}

                </div>
              ))}
            </div>
          ) : (
            <p className="text-attendee-name text-gray-500 flex justify-center mt-4">No VIPs, see host to become one.</p>
          )}

          {/* Recommended */}
          <h6 className="mb-1.5 ml-1.5 text-1xl mt-7 text-event-footer">Recommended</h6>
          {recommendedUsers.length > 0 ? (
            <div className="flex flex-wrap justify-start gap-4 text-2xl text-name-700 dark:text-white">
              {recommendedUsers.map((recommended) => (
                <div className="itemalign" onClick={() => selectRecommendedUserProfile(recommended)} key={recommended.id}>
                  <div className="rounded-full overflow-hidden h-12 w-12 border-1 border-black ml-6">
                    <img
                      className="h-26 w-26 object-cover"
                      src={recommended.user.photoUrl || user}
                      alt="user"
                    />
                  </div>
                  <h6 className="mb-.5 mr-222 text-attendee-name dark:text-white flex items-center justify-center">
                    {recommended.user.firstName}
                  </h6>
                  <p className="mr-222 text-attendee-name dark:text-white flex items-center justify-center">
                    {recommended?.user.makeFriends && '🎉'}
                    {recommended?.user.network && "🤝"}
                    {recommended?.user.date && "❤️"}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-attendee-name text-gray-500 flex justify-center mt-4">No recommendations, check later.</p>
          )}


          {/* Attendees */}
          <h6 className="mb-1.5 ml-1.5 text-1xl mt-7 text-event-footer">Attendees</h6>
          {otherUsers.length > 0 ? (
            <div className="flex flex-wrap justify-start gap-4 text-2xl text-name-700 dark:text-white">
              {otherUsers.map((attendee) => (
                <div className="itemalign" onClick={() => selectUserProfile(attendee)} key={attendee.id}>
                  <div className="rounded-full overflow-hidden h-12 w-12 border-2 border-black ml-6">
                    <img
                      className="h-26 w-26 object-cover"
                      src={attendee.user.photoUrl || user}
                      alt="user"
                    />
                  </div>
                  <h6 className="mb-.5 mr-222 text-attendee-name dark:text-white flex items-center justify-center">
                    {attendee.user.firstName}
                  </h6>
                  <p className="mr-222 text-attendee-name dark:text-white flex items-center justify-center">
                    {attendee?.user.makeFriends && '🎉'}
                    {attendee?.user.network && "🤝"}
                    {attendee?.user.date && "❤️"}</p>
                </div>
              ))}
            </div>
          ) : (
            <div MdOutlineQrCodeScanner onClick={handleQRCodeIconClick}><p className="text-attendee-name text-gray-500 flex justify-center mt-4">Tap here and share with others in the venue.</p>
            </div>
          )}
          {/* Dialog to display user profile */}
          <Dialog
            open={isOpen}
            onClose={closeUserProfile}
            fullScreen
          >
            <div className="flex overflow-y-auto hide-scrollbar inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white relative"> {/* Add relative positioning */}
                {/* White background row */}
                <div className="bg-white flex justify-end sticky top-0 z-10"> {/* Add sticky positioning */}
                  <button className="p-2" onClick={closeUserProfile}>
                    <MdOutlineClose className="h-6 w-6" />
                  </button>
                </div>
                <DialogTitle>
                  <div className="flex justify-between items-center w-full">
                    <div></div>
                  </div>
                </DialogTitle>
                <DialogContent>
                  {selectedUser && (
                    <div>
                      <UserProfile selectedUserId={selectedUserId} />
                    </div>
                  )}
                </DialogContent>
              </div>
            </div>
          </Dialog>
          <footer className="global-footer">
            {/* Separation line */}
            <div className="border-t border-gray-200 mt-4"></div>

            {/* Text for hosting event on spots */}
            <div className="flex justify-center mt-4">
              <p className="text-event-footer mb-4 cursor-pointer" onClick={() => window.open("http://meetatspots.com/#about", "_blank")} rel="noopener noreferrer">
                Host your event on spots
              </p>
            </div>
          </footer>

        </div>

      </div>
    </>
  );
}