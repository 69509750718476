import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';

const QRScanner = () => {
  const [result, setResult] = useState('');

  const handleScan = data => {
    if (data) {
      setResult(data);
      console.log('scanned');
    }
  };

  const handleError = err => {
    console.error(err);
  };

  return (
    <div className="qr-scanner-container"> {/* Apply CSS class for styling */}
      <h1>Scan your QR Code below</h1>
      <div className="react-qr-reader-container"> {/* Container for QR reader */}
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
      </div>
      <p>{result}</p>
    </div>
  );
};

export default QRScanner;
