// Custom components
import React from "react";

function InputField(props) {
  const {
    id,
    label,
    extra,
    inputStyle,
    type,
    value,
    placeholder,
    state,
    disabled,
    maxLength = 1560,
    onChange,
    index,
    pattern = "*",
    inputmode = "text",
    onKeyDown,
  } = props;

  return (
    <div className={`${extra}`}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <input
        onKeyDown={onKeyDown}
        tabIndex={index}
        autoFocus
        disabled={disabled}
        type={type}
        value={value}
        onChange={(event) => onChange(event)}
        id={id}
        placeholder={placeholder}
        pattern={pattern}
        inputMode={inputmode}
        maxLength={maxLength}
        className={`${inputStyle} mt-2 flex h-12 w-full items-center justify-center rounded-xl border p-3 text-sm text-navy-700 outline-none  ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:text-white dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-200 dark:!border-white/10 "
        }`}
      />
    </div>
  );
}

export default InputField;
