import { useLocation, useNavigate } from "react-router-dom";
import httpService from "services/http-service";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import { MdArrowBackIos } from "react-icons/md";
import user from "assets/img/icons/user.png";
import { calculateAge } from "utils/utils";
import Chip from '@mui/material/Chip';
import { FaInstagram, FaLinkedin, FaRegPaperPlane } from "react-icons/fa";
import { EventUserInteractionType } from "utils/utils";
import { getLocalStorageValue } from "utils/utils";
import { getLocalStorageKey } from "utils/utils";
//import Swal from "sweetalert2";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";



// Define JSON data directly in the code
const jsonData = {
  name: "Misha",
  age: 18,
  gender: "Female",
  company: "S Company"
};

export default function UserProfile({ selectedUserId }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [waveClicked, setWaveClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const followId = parseInt(window.location.hash.replace("#", ""));
  const [userData, setUserData] = useState(null); // State to hold follow data
  const [showReactions, setShowReactions] = useState(false);
  const [reactionClicked, setReactionClicked] = useState(false);
  const [popupRef, setPopupRef] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState('');

  const getEmoji = (eventType) => {
    switch (eventType) {
      case EventUserInteractionType.Date:
        return "❤️";
      case EventUserInteractionType.Network:
        return "🤝";
      case EventUserInteractionType.MakeFriends:
        return "🎉";
      default:
        return "";
    }
  };
  // Function to handle reaction click with confirmation
  const handleReactionClickWithConfirmation = (eventType) => {
    setSelectedEventType(eventType); // Set the selected event type
    openDialog();
  };

  // Function to handle opening the dialog
  const openDialog = (eventType) => {
    setSelectedEventType(eventType);
    setDialogOpen(true);
  };

  // Function to handle closing the dialog
  const closeDialog = () => {
    setDialogOpen(false);
  };

  // Function to handle confirming the action
  const confirmAction = () => {
    handleReactionClick(selectedEventType);
    closeDialog();
  };



  const handleClick = () => {
    setShowReactions(prevState => !prevState); // Toggle the popup
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowReactions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handleReactionClick = async (eventType) => {
    const eventId = getLocalStorageKey('eventId');
    const userId = getLocalStorageValue('userDetails', 'userId');

    const eventInteractionData = {
      id: 0,
      eventId: eventId,
      swipingUser: userId,
      userSwipedOn: selectedUserId,
      type: eventType
    }
    setLoading(true);
    const endpoint = `/EventUserInteraction`;
    const response = await httpService("POST", endpoint, eventInteractionData);
    setLoading(false);
    if (!response) {
      toast.error('Something went wrong')
    } else {
      toast.success("We let them know that you've made reaction of " + eventType);
    }

    setShowReactions(false);
    setReactionClicked(true);
  };




  useEffect(() => {
    if (selectedUserId) {
      getUserProfile(selectedUserId)
    } else {
      const userId = localStorage.getItem('selectedUserId')
      if (userId) {
        getUserProfile(userId)
      } else {
        toast.error('No user found')
      }

    }
  }, []);


  const getUserProfile = async (id) => {
    setLoading(true);
    const endpoint = `/User/${id}`;
    const response = await httpService("GET", endpoint);
    setLoading(false);
    if (response.Error == "HttpException") {
      return response;
    } else {
      setUserData(response)
    }


  };
  const handleWaveClick = () => {
    setWaveClicked(true);
    toast.success("We let them know that you've waved.");
  };
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };
  return (
    <>
      <div className="mb-8 flex overflow-y-visible hide-scrollbar h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
        <Loading loading={loading} loaderColor="#252424" />
        <div className="px-8 w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <div>
            <img class="h-full w-full mt-4 object-cover shadow-bottom custom-border-radius" src={userData?.userProfilePhotos[0]?.photoUrl || user} alt="user" />
          </div>

          <div class="flex justify-between mt-2 mb-2">

          </div>
          <div className="mt-4 mb-6">
            <>
              <div class="flex items-center">
                <h4 class="mb-2.5 text-2xl font-bold dark:text-white mr-2 mt-1">{userData?.firstName}</h4>
                <p class="text-base font-medium text-gray-500">{userData?.dateOfBirth !== null ? (calculateAge(userData?.dateOfBirth))
              :(
                <p class="text-base font-medium text-gray-500"></p>
              )}
               </p>
              </div>
              <p class="text-base font-medium text-gray-500">{userData?.sex}</p>
              <p class="text-base font-medium text-gray-500">{userData?.title}</p>
            </>
          </div>
          <h5 class="mb-2.5 text-1xl dark:text-white mr-2 mt-1">Interested in</h5>
          {userData?.makeFriends && <Chip
            label="🎉 Friendships"
            variant="outlined"
            className="m-1"
            style={{ cursor: 'pointer' }}
          />}
          {userData?.network && <Chip
            label="🤝 Networking"
            variant="outlined"
            className="m-1"
            style={{ cursor: 'pointer' }}
          />}
          {userData?.date && <Chip
            label="❤️ Dating"
            variant="outlined"
            className="m-1"
            style={{ cursor: 'pointer' }}
          />}
          {userData?.userStatusDetails && userData.userStatusDetails.length > 0 && userData.userStatusDetails.map(detail => (
            <Chip
              key={detail?.id}
              label={detail?.choice}
              variant="outlined"
              className="m-2" />
          ))}

        </div>
      </div>
      <div className="rounded-lg custom-width blurry-background sticky-bottom-user w-full pt-2">
        <div className="relative flex items-center justify-space-between mb-5">
          {/* Display reaction icons */}
          {showReactions && !reactionClicked && (
            <div
              ref={setPopupRef}
              className="absolute top-0 left-48 -mt-14 bg-white p-2 flex items-center justify-between"
            >
              <span
                role="img"
                aria-label="heart"
                className="mr-2 text-2xl cursor-pointer"
                onClick={() =>
                  handleReactionClickWithConfirmation(EventUserInteractionType.Date)
                }
              >
                ❤️
              </span>
              <span
                role="img"
                aria-label="handshake"
                className="mr-2 text-2xl cursor-pointer"
                onClick={() =>
                  handleReactionClickWithConfirmation(
                    EventUserInteractionType.Network
                  )
                }
              >
                🤝
              </span>
              <span
                role="img"
                aria-label="party"
                className="text-2xl cursor-pointer"
                onClick={() =>
                  handleReactionClickWithConfirmation(
                    EventUserInteractionType.MakeFriends
                  )
                }
              >
                🎉
              </span>
            </div>
          )}
          {/* Display social icons */}
          <FaLinkedin onClick={() => handleLinkClick(userData?.linkedIn)} className="icon linkedin-icon ml-4 text-3xl cursor-pointer" />
          <FaInstagram onClick={() => handleLinkClick('https://instagram.com/' + userData?.instagram)} className="icon instagram-icon text-pink-600 text-3xl cursor-pointer" />
          {/* Display paper plane icon */}
          {
            <span
              role="img"
              aria-label="wave"
              className={`text-3xl mr-10 cursor-pointer wave-emoji ${reactionClicked ? 'text-gray-400' : ''}`}
              onClick={handleClick}
            >
              <FaRegPaperPlane className={`${reactionClicked ? 'text-gray-400' : 'paper-plane-icon'}`} />
            </span>
          }
        </div>
      </div>

      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Connection request</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You want to let them know you're interested in connecting? {getEmoji(selectedEventType)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            No
          </Button>
          <Button onClick={confirmAction} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
