import React from "react";
import Card from "components/card";
import httpService from "services/http-service";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";

const MyWorkplaces = ({ type = "", headerTitle = "", buttonText = "" }) => {
  const [workplaces, setWorkplaces] = React.useState([]);
  const [selectedWorkplace, setSelectedWorkplace] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [selectedMessage, setSelectedMessage] = React.useState(6);

  React.useEffect(() => {
    getAllWorkPlaces();
  }, []);

  const getAllWorkPlaces = async () => {
    setLoading(true);
    const endpoint = `/workplaces/getAll`;
    const response = await httpService("GET", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      setLoading(false);
      setWorkplaces(response);
    }
  };

  const messageRegulars = async () => {
    setLoading(true);
    const endpoint = "/workplaces/sendMessageAllRegulars";
    const data = {
      message: selectedMessage,
      workPlaceName: selectedWorkplace.Workplace,
    };

    const response = await httpService("POST", endpoint, data);
    if (response.Error) {
      setLoading(false);
      toast.error("We couldn't message your regulars :(");
    } else {
      if(response?.isTimeRemaining) {
        toast.warn(response?.message);
      } else {
        toast.success("Gliddy SMS has been sent");
      }
      updateWorkStatus(true);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const endpoint = `/workplaces/updateConfirmWorkPlace/${selectedWorkplace.Id}`;
    const response = await httpService("PUT", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      localStorage.setItem("isWorking", true);
      messageRegulars();
    }
  };

  const updateWorkStatus = async (workStatus) => {
    setLoading(true);
    const endpoint = `/bartenders/updateWorkStatus`;
    const data = { isWorking: workStatus };
    const response = await httpService("POST", endpoint, data);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      window.location.reload();
      setLoading(false);
    }
  };

  const handleSelectWorkplace = async (data) => {
    setSelectedWorkplace(data);
  };

  return (
    <Card extra={"mt-3 mb-10 !z-5"}>
      <Loading loading={loading} loaderColor="#252424" />
      {/* MyWorkplaces Header */}
      <div className="flex items-center justify-between rounded-t-3xl p-3">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          {headerTitle}
        </div>
      </div>

      {/* History CardData */}

      <div className="list-scroll">
        {workplaces.map((data, index) => (
          <div
            key={data.Id}
            className="flex h-full w-full items-start justify-between overflow-auto bg-white px-3 py-[10px] text-navy-700 hover:!bg-brand-600 hover:text-white dark:!bg-navy-800 dark:text-white dark:shadow-none dark:hover:!bg-brand-600 md:overflow-scroll"
          >
            <a
              className="list-border active"
              onClick={() => handleSelectWorkplace(data)}
            >
              <div className="m-[10px] flex items-center gap-3">
                <div className="flex h-16 w-16 items-center justify-center">
                  <img
                    className="h-full w-full rounded-xl"
                    src={data.ImageURL}
                    alt=""
                  />
                </div>

                <div className="flex w-56 flex-col">
                  <h5 className="text-base font-bold"> {data.Workplace}</h5>
                  <p className="mt-1 text-sm font-normal"> {data.Address} </p>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>

      <div className="flex items-center justify-between">
        <button
          onClick={() => handleSave()}
          disabled={selectedWorkplace.Workplace ? false : true}
          className={
            selectedWorkplace.Workplace
              ? "linear w-90 m-4 mt-5 mb-5 w-full rounded-lg bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              : "linear w-90 m-4 mt-5 mb-5 w-full cursor-not-allowed rounded-lg bg-brand-500 py-[12px] text-base font-medium text-white opacity-50 transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          }
        >
          {" "}
          {buttonText}
        </button>
      </div>
    </Card>
  );
};

export default MyWorkplaces;
