// import { industries } from "../../mocks/mockIndustries";

export const bodyTypesList = [
  {
    label: "Average",
    value: "Average",
  },
  {
    label: "Fit",
    value: "Fit",
  },
  {
    label: "Curvy",
    value: "Curvy",
  },
  {
    label: "Slim",
    value: "Slim",
  },
  {
    label: "A little extra",
    value: "A little extra",
  },
];

export const ReportTypes = [
  {
    label: "I'm not interested in this person",
    value: "NotInterested",
  },
  {
    label: "Profile is fake, spam or scammer",
    value: "FakeSpamOrScam",
  },
  {
    label: "Iappropriate content",
    value: "IappropriateContent",
  },
  {
    label: "Underage or Minor",
    value: "Underage",
  },
  {
    label: "Offline behaviour",
    value: "Offline behaviour",
  },
  {
    label: "Someone is in danger",
    value: "Danger",
  },
];
export const photoSourceTypesList = [
  // {
  //   label: "Camera",
  //   value: "camera",
  // },
  {
    label: "Image Gallery",
    value: "imageGallery",
  },
];

export const More_Options = [
  {
    title: "Gender",
    data: [
      "Intersex",
      "Trans Man",
      "Trans Woman",
      "Non-binary (Male)",
      "Non-binary (Female)",
    ],
  },
  // {
  //   title: "Pronouns",
  //   data: ["He/Him/His", "She/Her/Hers", "They/Them"],
  // },
];
export const genderRadios = [
  {
    label: "Man ",
    value: "man",
  },
  {
    label: "Woman ",
    value: "woman",
  },
  {
    label: "More Options ",
    value: "more-options",
  },
];

export const photoVideoSourceTypesList = [
  {
    label: "Photo",
    icon: "camera-outline",
    value: "camera",
  },
  {
    label: "Video",
    icon: "ios-videocam-outline",
    value: "video",
  },
];

export const photoVideoChatSourceTypesList = [
  {
    label: "Photo",
    icon: "camera-outline",
    value: "camera",
  },
  // { - removed for mvp
  //   label: "Video",
  //   icon: 'ios-videocam-outline',
  //   value: "video",
  // },
  {
    label: "Chat",
    icon: "ios-chatbubble-outline",
    value: "Chat",
  },
];
export const filterLocationMenu = [
  {
    label: "Location",
    icon: "chevron-down",
    value: "Location",
  },
  {
    label: "Preferences",
    icon: "chevron-down",
    value: "Preferences",
  },
];

export const editProfileMenu = [
  // {
  //   label: "My Membership",
  //   icon: "crown",
  //   value: "Membership",
  //   route: "PremiumOffer",
  // },
  {
    label: "Edit Profile",
    icon: "pencil-outline",
    value: "Edit Profile",
    route: "Edit Profile",
  },
  {
    label: "Preferences",
    icon: "sliders",
    value: "preferences",
    route: "Preferences",
  },
  {
    label: "Connect Instagram",
    icon: "instagram",
    value: "instagram",
    route: "instagram",
  },
  // {
  //   label: "Push Notifications",
  //   icon: "",
  //   value: "pushnotification",
  //   route: "",
  // },
  // {
  //   label: "Help Center",
  //   icon: "ambulance",
  //   value: "Help Center",
  //   route: "Help Center",
  // },
  {
    label: "Contact Support",
    icon: "address-book-o",
    value: "Contact Support",
    route: "Contact Support",
  },
  // {
  //   label: "QR Code",
  //   icon: "qrcode",
  //   value: "QR Code",
  //   route: "QR Code",
  // },
  // {
  //   label: "Locations",
  //   icon: "map",
  //   value: "Locations",
  //   route: "LocationsScreen",
  // },
  // {
  //   label: "Invite friends",
  //   icon: "share-square-o",
  //   value: "Invite friends",
  //   route: "Invite friends",
  // },
];
export const timeRanges = [
  //   {
  //   label: 'Anytime',
  //   value: 'Anytime',
  // },
  {
    label: "Today",
    value: "Today",
  },
  {
    label: "Tomorrow",
    value: "Tomorrow",
  },
  {
    label: "This Week",
    value: "This Week",
  },
  {
    label: "This Weekend",
    value: "This Weekend",
  },
  // {
  //   label: "Choose a date...",
  //   value: "Choose a date",
  // },
];

export const conactUsOptions = [
  {
    label: "Help center",
    value: "Help center",
    icon: "help-circle-outline",
  },
  {
    label: "Report a technical issue",
    value: "Report a technical issue",
    icon: "settings-outline",
  },
  {
    label: "Report a billing issue",
    value: "Report a billing issue",
    icon: "card-outline",
  },
  {
    label: "Suggest an idea",
    value: "Suggest an idea",
    icon: "bulb-outline",
  },
  {
    label: "Ask a question",
    value: "Ask a question",
    icon: "help-outline",
  },
];

// export const industryTypes = industries;

export const USStates = [
  {
    label: "Alabama",
    value: "Alabama",
    abbreviation: "AL",
  },
  {
    label: "Alaska",
    value: "Alaska",
    abbreviation: "AK",
  },
  {
    label: "American Samoa",
    value: "American Samoa",
    abbreviation: "AS",
  },
  {
    label: "Arizona",
    value: "Arizona",
    abbreviation: "AZ",
  },
  {
    label: "Arkansas",
    value: "Arkansas",
    abbreviation: "AR",
  },
  {
    label: "California",
    value: "California",
    abbreviation: "CA",
  },
  {
    label: "Colorado",
    value: "Colorado",
    abbreviation: "CO",
  },
  {
    label: "Connecticut",
    value: "Connecticut",
    abbreviation: "CT",
  },
  {
    label: "Delaware",
    value: "Delaware",
    abbreviation: "DE",
  },
  {
    label: "District Of Columbia",
    value: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    label: "Florida",
    value: "Florida",
    abbreviation: "FL",
  },
  {
    label: "Georgia",
    value: "Georgia",
    abbreviation: "GA",
  },
  {
    label: "Guam",
    value: "Guam",
    abbreviation: "GU",
  },
  {
    label: "Hawaii",
    value: "Hawaii",
    abbreviation: "HI",
  },
  {
    label: "Idaho",
    value: "Idaho",
    abbreviation: "ID",
  },
  {
    label: "Illinois",
    value: "Illinois",
    abbreviation: "IL",
  },
  {
    label: "Indiana",
    value: "Indiana",
    abbreviation: "IN",
  },
  {
    label: "Iowa",
    value: "Iowa",
    abbreviation: "IA",
  },
  {
    label: "Kansas",
    value: "Kansas",
    abbreviation: "KS",
  },
  {
    label: "Kentucky",
    value: "Kentucky",
    abbreviation: "KY",
  },
  {
    label: "Louisiana",
    value: "Louisiana",
    abbreviation: "LA",
  },
  {
    label: "Maine",
    value: "Maine",
    abbreviation: "ME",
  },
  {
    label: "Marshall Islands",
    value: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    label: "Maryland",
    value: "Maryland",
    abbreviation: "MD",
  },
  {
    label: "Massachusetts",
    value: "Massachusetts",
    abbreviation: "MA",
  },
  {
    label: "Michigan",
    value: "Michigan",
    abbreviation: "MI",
  },
  {
    label: "Minnesota",
    value: "Minnesota",
    abbreviation: "MN",
  },
  {
    label: "Mississippi",
    value: "Mississippi",
    abbreviation: "MS",
  },
  {
    label: "Missouri",
    value: "Missouri",
    abbreviation: "MO",
  },
  {
    label: "Montana",
    value: "Montana",
    abbreviation: "MT",
  },
  {
    label: "Nebraska",
    value: "Nebraska",
    abbreviation: "NE",
  },
  {
    label: "Nevada",
    value: "Nevada",
    abbreviation: "NV",
  },
  {
    label: "New Hampshire",
    value: "New Hampshire",
    abbreviation: "NH",
  },
  {
    label: "New Jersey",
    value: "New Jersey",
    abbreviation: "NJ",
  },
  {
    label: "New Mexico",
    value: "New Mexico",
    abbreviation: "NM",
  },
  {
    label: "New York",
    value: "New York",
    abbreviation: "NY",
  },
  {
    label: "North Carolina",
    value: "North Carolina",
    abbreviation: "NC",
  },
  {
    label: "North Dakota",
    value: "North Dakota",
    abbreviation: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    label: "Ohio",
    value: "Ohio",
    abbreviation: "OH",
  },
  {
    label: "Oklahoma",
    value: "Oklahoma",
    abbreviation: "OK",
  },
  {
    label: "Oregon",
    value: "Oregon",
    abbreviation: "OR",
  },
  {
    label: "Palau",
    value: "Palau",
    abbreviation: "PW",
  },
  {
    label: "Pennsylvania",
    value: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    label: "Rhode Island",
    value: "Rhode Island",
    abbreviation: "RI",
  },
  {
    label: "South Carolina",
    value: "South Carolina",
    abbreviation: "SC",
  },
  {
    label: "South Dakota",
    value: "South Dakota",
    abbreviation: "SD",
  },
  {
    label: "Tennessee",
    value: "Tennessee",
    abbreviation: "TN",
  },
  {
    label: "Texas",
    value: "Texas",
    abbreviation: "TX",
  },
  {
    label: "Utah",
    value: "Utah",
    abbreviation: "UT",
  },
  {
    label: "Vermont",
    value: "Vermont",
    abbreviation: "VT",
  },
  {
    label: "Virgin Islands",
    value: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    label: "Virginia",
    value: "Virginia",
    abbreviation: "VA",
  },
  {
    label: "Washington",
    value: "Washington",
    abbreviation: "WA",
  },
  {
    label: "West Virginia",
    value: "West Virginia",
    abbreviation: "WV",
  },
  {
    label: "Wisconsin",
    value: "Wisconsin",
    abbreviation: "WI",
  },
  {
    label: "Wyoming",
    value: "Wyoming",
    abbreviation: "WY",
  },
];

export const heightTypes = [
  {
    label: `3’0"`,
    value: `3’0`,
  },
  {
    label: `3’1"`,
    value: `3’1`,
  },
  {
    label: `3’2"`,
    value: `3’2`,
  },
  {
    label: `3’3"`,
    value: `3’3`,
  },
  {
    label: `3’4"`,
    value: `3’4`,
  },
  {
    label: `3’5"`,
    value: `3’5`,
  },
  {
    label: `3’6"`,
    value: `3’6`,
  },
  {
    label: `3’7"`,
    value: `3’7`,
  },
  {
    label: `3’8"`,
    value: `3’8`,
  },
  {
    label: `3’9"`,
    value: `3’9`,
  },
  {
    label: `3’10"`,
    value: `3’10`,
  },
  {
    label: `3’11"`,
    value: `3’11`,
  },
  {
    label: `4’0"`,
    value: `4’0`,
  },
  {
    label: `4’1"`,
    value: `4’1`,
  },
  {
    label: `4’2"`,
    value: `4’2`,
  },
  {
    label: `4’3"`,
    value: `4’3`,
  },
  {
    label: `4’4"`,
    value: `4’4`,
  },
  {
    label: `4’5"`,
    value: `4’5`,
  },
  {
    label: `4’6"`,
    value: `4’6`,
  },
  {
    label: `4’7"`,
    value: `4’7`,
  },
  {
    label: `4’8"`,
    value: `4’8`,
  },
  {
    label: `4’9"`,
    value: `4’9`,
  },
  {
    label: `4’10"`,
    value: `4’10`,
  },
  {
    label: `4’11"`,
    value: `4’11`,
  },
  {
    label: `5’0"`,
    value: `5’0`,
  },
  {
    label: `5’1"`,
    value: `5’1`,
  },
  {
    label: `5’2"`,
    value: `5’2`,
  },
  {
    label: `5’3"`,
    value: `5’3`,
  },
  {
    label: `5’4"`,
    value: `5’4`,
  },
  {
    label: `5’5"`,
    value: `5’5`,
  },
  {
    label: `5’6"`,
    value: `5’6`,
  },
  {
    label: `5’7"`,
    value: `5’7`,
  },
  {
    label: `5’8"`,
    value: `5’8`,
  },
  {
    label: `5’9"`,
    value: `5’9`,
  },
  {
    label: `5’10"`,
    value: `5’10`,
  },
  {
    label: `5’11"`,
    value: `5’11`,
  },
  {
    label: `6’0"`,
    value: `6’0`,
  },
  {
    label: `6’1"`,
    value: `6’1`,
  },
  {
    label: `6’2"`,
    value: `6’2`,
  },
  {
    label: `6’3"`,
    value: `6’3`,
  },
  {
    label: `6’4"`,
    value: `6’4`,
  },
  {
    label: `6’5"`,
    value: `6’5`,
  },
  {
    label: `6’6"`,
    value: `6’6`,
  },
  {
    label: `6’7"`,
    value: `6’7`,
  },
  {
    label: `6’8"`,
    value: `6’8`,
  },
  {
    label: `6’9"`,
    value: `6’9`,
  },
  {
    label: `6’10"`,
    value: `6’10`,
  },
  {
    label: `6’11"`,
    value: `6’11`,
  },
  {
    label: `7’0"`,
    value: `7’0`,
  },
  {
    label: `7’1"`,
    value: `7’1`,
  },
  {
    label: `7’2"`,
    value: `7’2`,
  },
  {
    label: `7’3"`,
    value: `7’3`,
  },
  {
    label: `7’4"`,
    value: `7’4`,
  },
  {
    label: `7’5"`,
    value: `7’5`,
  },
  {
    label: `7’6"`,
    value: `7’6`,
  },
  {
    label: `7’7"`,
    value: `7’7`,
  },
  {
    label: `7’8"`,
    value: `7’8`,
  },
  {
    label: `7’9"`,
    value: `7’9`,
  },
  {
    label: `7’10"`,
    value: `7’10`,
  },
  {
    label: `7’11"`,
    value: `7’11`,
  },
];
export const EducationLevels = [
  {
    label: "High School",
    value: "High School",
  },
  {
    label: "Some College",
    value: "Some College",
  },
  {
    label: "Associate’s Degree",
    value: "Associate’s Degree",
  },
  {
    label: "Bachelor’s Degree",
    value: "Bachelor’s Degree",
  },
  {
    label: "Graduate Degree",
    value: "Graduate Degree",
  },

  {
    label: "PhD/Post Doctoral",
    value: "PhD/Post Doctoral",
  },
];

export const goals = [
  {
    name: "Hire someone",
    isSelected: false,
  },
  {
    name: "Find a job",
    isSelected: false,
  },
  {
    name: "Find co-founders",
    isSelected: false,
  },
  {
    name: "Invest",
    isSelected: false,
  },
  {
    name: "Find investors",
    isSelected: false,
  },
  {
    name: "Find mentors",
    isSelected: false,
  },
  {
    name: "Mentor others",
    isSelected: false,
  },
  {
    name: "Find customers",
    isSelected: false,
  },
  {
    name: "Other",
    isSelected: false,
  },
];

export const openTo = [
  {
    name: "Date Seriously",
    icon: "💘",
    isSelected: false,
    id: 1,
  },
  {
    name: "Date Casually",
    icon: "😘",
    isSelected: false,
    id: 2,
  },
  // {
  //   name: "An Adventure",
  //   icon: "😈",
  //   isSelected: false,
  //   id: 3,
  // },
];

export const vibeMessages = [
  {
    name: "Empty",
  },
  {
    name: "Crowded",
  },
  {
    name: "Too crowded",
  },
  {
    name: "Low energy",
  },
  {
    name: "High energy",
  },

  {
    name: "Max Energy",
  },
];

export const interestList = [
  {
    name: "Date",
    icon: "❤️",
    isSelected: false,
    id: 1,
  },
  {
    name: "Make Friends",
    icon: "🎉",
    isSelected: false,
    id: 2,
  },
  {
    name: "Network",
    icon: "🤝",
    isSelected: false,
    id: 3,
  },
];


export const genders = [
  'Man', 'Woman'
]
export const friendsGender = [
  'Men', 'Women'
  
]


export const industries = [
  "Arts, Culture and Entertainment",
  "Beauty",
  "Construction",
  "Education",
  "Finance and Insurance",
  "Fitness and Sport",
  "Government",
  "Fashion",
  "Healthcare",
  "Hospitality",
  "Information Technology",
  "Marketing & Advertising",
  "Manufacturing",
  "Media and News",
  "Medical",
  "Non-profit",
  "Real estate",
  "Telecommunication",
  "Transportation",
  "Wellness"
];

export const interests = [
  "Art Galleries",
  "Bars",
  "Beaches",
  "Bowling",
  "Camping",
  "Comedy Clubs",
  "Concerts",
  "Coffee Shops",
  "Hookah",
  "Festivals",
  "Nightclubs",
  "Outdoors",
  "Baking",
  "Beer",
  "Cooking",
  "Dessert",
  "Cannabis",
  "Cocktails",
  "Mocktails",
  "Mixology",
  "Shots",
  "Sushi",
  "Tea",
  "Veganism",
  "Vegetarianism",
  "Wine",
  "Cycling",
  "Hiking",
  "Pickleball",
  "Pilates",
  "Running",
  "Soccer",
  "Tennis",
  "Yoga",
  "Gym",
  "HIIT",
  "MLB",
  "Nascar",
  "NBA",
  "NCAA",
  "NFL",
  "NHL",
  "UFC",
  "WWE",
  "Adrenaline Junkie",
  "Board Games",
  "Crypto",
  "Dancing",
  "Emotional Intelligence",
  "Entrepreneurship",
  "Family",
  "Gaming",
  "Gardening",
  "Manga",
  "Mental Health",
  "Road Trips",
  "Science",
  "Singing",
  "Photography",
  "Dogs",
  "Cats",
  "Afrobeat",
  "Country Music",
  "EDM",
  "Heavy Metal",
  "Hip-hop/R'n'B",
  "K-Pop",
  "Rock’n’Roll",
  "Top 40",
  "Reggaeton",
  "Action Movies",
  "Anime",
  "Audiobooks",
  "Bollywood",
  "Comedy",
  "International TV",
  "Horror",
  "Fantasy",
  "Love Stories",
  "Nollywood",
  "Thrillers",
  "Podcasts",
  "Reading",
  "Sci-fi",
  "Tubi",
  "Astrology",
  "Atheism",
  "Agnosticism",
  "Buddhism",
  "Christianity",
  "Judaism",
  "Hinduism",
  "Islam",
  "Meditation",
  "Mindfulness",
  "Taoism",
  "Self-development",
  "Social Activism",
  "Politics"
];

