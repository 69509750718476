import React from "react";
 

// Auth Imports
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/SignUp";

// Icon Imports
import {
  MdLocalPhone,
  MdVerifiedUser,
  MdLock,
} from "react-icons/md";
import Verify from "views/auth/Verify";
import Workplace from "views/auth/Workplace";
import Landing from "views/auth/Landing";
import Queensbar from "views/auth/Queensbar";
import Howitworks from "views/auth/Howitworks";
import CustomerHome from "views/auth/CustomerHome";
import CustomerFollowings from "views/auth/CustomerFollowings";
import Settings from "views/auth/Settings";
import Editprofile from "views/auth/Editprofile";
import Feedback from "views/auth/Feedback";
import Event from "views/auth/Event";
import UserProfile from "views/auth/UserProfile";
import QRScanner from "views/auth/QRReader";
import Qrcode from "views/auth/Qrcode";
import Notifications from "views/auth/Notification";
import EventScan from "views/auth/Eventscan";
import Home from "views/auth/Home";

const routes = [
  {
    name: "Sign In",
    layout: "/auth",
    path: "default",
    icon: <MdLock className="h-6 w-6" />,
    component: <Landing />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Phone Number",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLocalPhone className="h-6 w-6" />,
    component: <SignUp />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "regular-sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "QR SCAN",
    layout: "/auth",
    path: "qr-scanner",
    icon: <MdLocalPhone className="h-6 w-6" />,
    component: <QRScanner />,
  },
 
  {
    name: "QR Search",
    layout: "/auth",
    path: "search-event",
    icon: <MdLocalPhone className="h-6 w-6" />,
    component: <Qrcode />,
  },
 
  {
    name: "Spots",
    layout: "/auth",
    path: "landing",
    icon: <MdVerifiedUser className="h-6 w-6" />,
    component: <Landing />,
  },
  {
    name: "WorkPlace",
    layout: "/auth",
    path: "workplace",
    icon: <MdLock className="h-6 w-6" />,
    component: <Workplace />,
  },
  {
    name: "Queensbar",
    layout: "/auth",
    path: "workplace-home",
    icon: <MdLock className="h-6 w-6" />,
    component: <Queensbar />,
  },
 
  {
    name: "Edit Profile",
    layout: "/auth",
    path: "edit-profile",
    icon: <MdLock className="h-6 w-6" />,
    component: <Editprofile />,
  },
  {
    name: "Edit Profile",
    layout: "/auth",
    path: "events",
    icon: <MdLock className="h-6 w-6" />,
    component: <Event />,
  },
  {
    name: "Profile",
    layout: "/auth",
    path: "user-profile",
    icon: <MdLock className="h-6 w-6" />,
    component: <UserProfile />,
  },
  {
    name: "Notifications",
    layout: "/auth",
    path: "notifications",
    icon: <MdLock className="h-6 w-6" />,
    component: <Notifications />,
  },
  {
    name: "EventScan",
    layout: "/auth",
    path: "eventscan",
    icon: <MdLock className="h-6 w-6" />,
    component: <EventScan />,
  },
  {
    name: "Home",
    layout: "/auth",
    path: "home",
    icon: <MdLock className="h-6 w-6" />,
    component: <Home />,
  },
  {
    name: "Feedback",
    layout: "/auth",
    path: "send-feedback",
    icon: <MdLock className="h-6 w-6" />,
    component: <Feedback />,
  },

  {
    name: "How It Works",
    layout: "/auth",
    path: "welcome",
    icon: <MdLock className="h-6 w-6" />,
    component: <Howitworks />,
  },
  {
    name: "CustomerHome",
    layout: "/auth",
    path: "CustomerHome",
    icon: <MdLock className="h-6 w-6" />,
    component: <CustomerHome />,
  },
  {
    name: "CustomerFollowings",
    layout: "/auth",
    path: "CustomerFollowings",
    icon: <MdLock className="h-6 w-6" />,
    component: <CustomerFollowings />,
  },
];
export default routes;
