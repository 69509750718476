import React, { useEffect, useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import Card from "components/card";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InputField from "components/fields/InputField";
import httpService from "services/http-service";
import config from "utils/config";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";

export default function Invite({ title, description }) {
  const [loading, setLoading] = React.useState(false);

  const [textToCopy, setTextToCopy] = useState(
    config.INVITE_BASE_URL + `/auth/sign-up`
  );
  const populateData = async () => {
    setLoading(true);
    const endpoint = `bartenders/getUserInvitationId`;
    const response = await httpService("GET", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response?.Message);
    } else if (response) {
      setLoading(false);
      setTextToCopy(config.INVITE_BASE_URL + `/auth/sign-up/#${response}`);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const copyTextClipboard = async () => {
    toast.success("Link has been copied", {
      position: "bottom-center",
    });
    await navigator.clipboard.writeText(textToCopy);
  };

  return (
    <Card extra="!mt-[2rem] !p-[20px] !pb-[30px] h-50 border-2">
      <Loading loading={loading} loaderColor="#252424" />
      <div className="mt-[2rem]" ></div>
      <h3 className="justify-left items-left flex text-2xl font-bold text-navy-700 dark:text-white">
        {title}
      </h3>
      <h4 className="justify-left items-left mt-[15px] flex text-navy-700 dark:text-white">
        {description}
      </h4>
      <div className="mt-5 flex">
        <InputField
          type="text"
          extra="w-[80%] dark:text-white"
          value={textToCopy}
          disabled={true}
          onChange={(event) => setTextToCopy(event.target.value)}
          placeholder="Type your text here"
        />

        <CopyToClipboard
          text={textToCopy}
          onCopy={() => {
            copyTextClipboard();
          }}
        >
          <button className="ml-[20px] flex self-center text-brand-500">
            <MdOutlineContentCopy className="h-6 w-6" />
          </button>
        </CopyToClipboard>
      </div>
      {title === "Invite friends to join" && (
        <button
          onClick={() => {
            copyTextClipboard();
          }}
          className="linear mb-5 mt-10 w-full rounded-xl bg-brand-500 py-[12px] px-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Share link
        </button>
      )}
    </Card>
  );
}
