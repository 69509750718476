import React from "react";
import { MdOutlinePeopleAlt, MdOutlineSettings } from "react-icons/md";
import WorkplaceCard from "views/admin/default/components/WorkplaceCard";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";
import httpService from "services/http-service";
import Lottie from "react-lottie";
import confetti from "views/auth/confetti.json";
import GenericModal from "views/admin/default/components/GenericModal";
import { RegularcolumnsData } from "views/admin/tables/variables/columnsData";
import RegularsTable from "views/admin/tables/components/RegularsTable";
import { useNavigate } from "react-router-dom";
import logo from "assets/img/logo/logo.png";

export default function Workplace() {
  const confettiOptions = {
    loop: true,
    autoplay: true,
    animationData: confetti,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [totalRegulars, setTotalRegulars] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const userId = JSON.parse(localStorage.getItem("userDetails")).userId;

  React.useEffect(() => {
    localStorage.setItem("home", "/auth/workplace");
    setLoading(true);
    const fetchRegulars = async () => {
      const endpoint = `/bartenders/getAllRegulars/${userId}`;
      const response = await httpService("GET", endpoint);
      if (response.Error === "HttpException") {
        setLoading(false);
        toast.error(response.Message);
      } else {
        setLoading(false);
        setTotalRegulars(response.length);
      }
    };
    fetchRegulars();
  }, [userId]);

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  return (
    <div>
      <div className="flex h-full w-full justify-center px-2 pt-6 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
        <Lottie
          style={{ position: "absolute", height: 400, zIndex: 0 }}
          options={confettiOptions}
        />
        <Loading loading={loading} loaderColor="#252424" />
        {/* Bartender Welcome section */}
        <div className="w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <div className="flex h-20 w-full justify-center">
            <h4 className="absolute top-0 left-[35px] right-[19px] mt-[32px] flex justify-between text-2xl font-bold text-navy-700 dark:text-white">
              <div className="flex flex-col justify-between">
                <img
                  className="mt-2 mr-[-10px] h-[25px] w-[80px]"
                  src={logo}
                  alt="logo"
                />

                <GenericModal
                  modalTrigger={
                    <button className="justify-left mt-[8px] mb-12 flex text-[16px] font-[400] text-navy-700 dark:text-white">
                      <MdOutlinePeopleAlt className="mr-2 h-6 w-6" />
                      {totalRegulars}{" "}
                      {totalRegulars === 1 ? "Regular" : "Regulars"}
                    </button>
                  }
                  modalContent={
                    <RegularsTable columnsData={RegularcolumnsData} />
                  }
                />
              </div>
              <div className="flex justify-between self-start">
                <button
                  onClick={() => handleRouteClick("/auth/settings")}
                  className="!linear mr-2 mr-[2px] flex justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100  dark:bg-navy-900 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                >
                  <MdOutlineSettings className="h-6 w-6" />
                </button>
              </div>
            </h4>
          </div>
          <WorkplaceCard />
        </div>
      </div>
    </div>
  );
}
