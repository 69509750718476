import React, { useState } from 'react';
import Chip from '@mui/material/Chip';

function ChipList({ items, allowMultipleSelection, onSelect }) {
  const [selectedChips, setSelectedChips] = useState([]);

  const handleChipClick = (item) => {
    let updatedSelection = [];

    if (allowMultipleSelection) {
      if (selectedChips.includes(item)) {
        updatedSelection = selectedChips.filter((selectedItem) => selectedItem !== item);
      } else {
        updatedSelection = [...selectedChips, item];
      }
    } else {
      updatedSelection = [item];
    }

    setSelectedChips(updatedSelection);
    onSelect(updatedSelection);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
      {items.map((item, index) => (
        <Chip
          key={index}
          label={item}
          variant='outlined'
          clickable
          color={selectedChips.includes(item) ? 'primary' : 'default'}
          onClick={() => handleChipClick(item)}
        />
      ))}
    </div>
  );
}

export default ChipList;
