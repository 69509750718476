import React from "react";
import {
  MdPeopleAlt,
  MdOutlineShare,
  MdOutlineQrCodeScanner,
  MdOutlinePeopleAlt,
  MdOutlineSettings,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import GenericModal from "views/admin/default/components/GenericModal";
import Qrcode from "./Qrcode";
import RegularsTable from "views/admin/tables/components/RegularsTable";
import { RegularcolumnsData } from "views/admin/tables/variables/columnsData";
import Invite from "./Invite";
import { useState, useEffect } from "react";
import OfflineMode from "components/offlineMode/OfflineMode";
import { toast } from "react-toastify";
import httpService from "services/http-service";
import Loading from "react-fullscreen-loading";
import MyWorkplaces from "views/admin/default/components/MyWorkplaces";
import logo from "assets/img/logo/logo.png";

export default function Queensbar() {
  const [totalRegulars, setTotalRegulars] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [workplaceName, setWorkplaceName] = useState("");
  const [workplaces, setWorkplaces] = React.useState([]);
  const [selectedMessage, setSelectedMessage] = React.useState(6);
  const userId = JSON.parse(localStorage.getItem("userDetails")).userId;
  const localIsWorking = localStorage.getItem("isWorking");
  const localMessage = localStorage.getItem("message");
  const userName = JSON.parse(localStorage.getItem("userDetails")).userName;

  const handleButtonClick = (e) => {
    if (e === false) {
      if (workplaces.length === 1) {
        if (workplaceName) {
          updateWorkStatus(true);
          messageRegulars();
        }
      }
    } else {
      updateWorkStatus(false);
    }
  };
  const navigate = useNavigate();

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  useEffect(() => {
    localStorage.setItem("home", "/auth/workplace-home");
    fetchRegulars();
    getAllWorkPlaces();
    setIsWorking(localIsWorking === "false" ? false : true);
  }, [userId]);

  useEffect(() => {
    if (!isWorking) {
      document.body.classList.add("dark");
    } else {
      if (workplaces.length <= 1) {
        document.body.classList.remove("dark")
      }
    }
  }, [isWorking])

  const fetchRegulars = async () => {
    setLoading(true);
    const endpoint = `/bartenders/getAllRegulars/${userId}`;
    const response = await httpService("GET", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      setTotalRegulars(response.length);
    }
  };

  // const getLastWorkplace = async () => {
  //   setLoading(true);
  //   const endpoint = `/workplaces/getLastWorkPlace`;
  //   const response = await httpService("GET", endpoint);
  //   if (response.Error === "HttpException") {
  //     setLoading(false);
  //     toast.error(response.Message);
  //   } else {
  //     setLoading(false);
  //     setWorkplaceName(response.Data === "" ? response.Data : response);
  //     localStorage.setItem(
  //       "workPlaceName",
  //       response.Data === "" ? response.Data : response
  //     );
  //   }
  // };

  const messageRegulars = async () => {
    const endpoint = "/workplaces/sendMessageAllRegulars";
    const data = {
      message: selectedMessage,
      workPlaceName: workplaceName,
    };
    const response = await httpService("POST", endpoint, data);
    if (response.Error) {
      setLoading(false);
      toast.error("We couldn't message your regulars :(");
    } else {
      setLoading(false);
      if(response?.isTimeRemaining) {
        toast.warn(response?.message, {
          toastId: 'warning',
      });
      } else {
        toast.success("Gliddy SMS has been sent");
      }
    }
  };

  const getAllWorkPlaces = async () => {
    setLoading(true);
    const endpoint = `/workplaces/getAll`;
    const response = await httpService("GET", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
      updateWorkStatus(false);
      localStorage.setItem("workPlaceName", "");
    } else {
      setLoading(false);
      setWorkplaces(response);
      if (response.length === 1) {
        setWorkplaceName(response[0].Workplace);
        localStorage.setItem("workPlaceName", response[0].Workplace);
      } else if (response.length > 1) {
        const lastWorkplaceIndex = response
          .map((w) => w.IsCurrentWorkPlace)
          .indexOf(1);
        if (lastWorkplaceIndex > -1) {
          setWorkplaceName(response[lastWorkplaceIndex].Workplace);
          localStorage.setItem(
            "workPlaceName",
            response[lastWorkplaceIndex].Workplace
          );
        }
      }
    }
  };

  const updateWorkStatus = async (workStatus) => {
    setLoading(true);
    setIsWorking(workStatus);
    localStorage.setItem("isWorking", workStatus);
    const endpoint = `/bartenders/updateWorkStatus`;
    const data = { isWorking: workStatus };
    const response = await httpService("POST", endpoint, data);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="mt-[164px] flex h-full w-full items-center justify-center px-2 pt-6 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Bartender home section */}
      <Loading loading={loading} loaderColor="#252424" />
      <div className="mt-[0vh] w-full max-w-full  flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="!px-[30px] text-center ">
          <h4 className="absolute top-0 left-[35px] right-[19px] mt-[32px] flex items-center justify-between text-2xl font-bold text-navy-700 dark:text-white">
            <div className="flex flex-col justify-between">
              <img
                className="mt-2 mr-[-10px] h-[25px] w-[80px]"
                src={logo}
                alt="logo"
              />

              <GenericModal
                modalTrigger={
                  <button className="justify-left mt-[8px] mb-12 flex items-center text-[16px] font-[400] text-navy-700 dark:text-white">
                    <MdOutlinePeopleAlt className="mr-2 h-6 w-6" />
                    {totalRegulars}{" "}
                    {totalRegulars === 1 ? "Regular" : "Regulars"}
                  </button>
                }
                modalContent={
                  <RegularsTable columnsData={RegularcolumnsData} />
                }
              />
            </div>
            <div className="flex justify-between self-start">
              <GenericModal
                modalTrigger={
                  <button className="!linear mr-2 mr-[2px] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100  dark:bg-navy-900 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
                    <MdOutlineShare className="h-6 w-6" />
                  </button>
                }
                modalContent={
                  <Invite
                    title="Bring in other industry folks"
                    description="Your fellow bartenders will appreciate it, and so will we. Copy the link below."
                  />
                }
              />

              <GenericModal
                modalTrigger={
                  <button className="!linear mr-2 mr-[2px] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100  dark:bg-navy-900 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
                    <MdOutlineQrCodeScanner className="h-6 w-6" />
                  </button>
                }
                modalContent={<Qrcode bartenderName={userName} />}
              />
              <button
                onClick={() => handleRouteClick("/auth/settings")}
                className="!linear mr-2 mr-[2px] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100  dark:bg-navy-900 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
              >
                <MdOutlineSettings className="h-6 w-6" />
              </button>
            </div>
          </h4>
          <div className="flex items-center justify-between px-2">
            <div className="flex items-center"></div>
          </div>
          <h4
            style={{ fontSize: 19 }}
            className="text-navy-00 flex items-center justify-center text-base font-bold dark:text-white"
          >
            {isWorking ? "WORKING @" : "OFF WORK"}
          </h4>
          <h4 className=" mt-3 mb-3 flex  items-center justify-center text-4xl text-brand-500 dark:text-white">
            {workplaceName}
          </h4>
          <div className="mt-8 ml-1 flex justify-center">
            <GenericModal
              modalTrigger={
                <OfflineMode
                  onChange={(e) => handleButtonClick(e)}
                  isDisabled={workplaces.length === 0}
                  isWorking={isWorking}
                ></OfflineMode>
              }
              modalSuppress={isWorking || workplaces.length <= 1}
              modalContent={
                <MyWorkplaces
                  headerTitle="Select your workplace"
                  buttonText="Confirm Workplace"
                />
              }
            />
          </div>
        </div>
        <div className="mt-[5rem]">
          {isWorking && (
            <>
              <h4 className="flex items-center justify-center text-base text-navy-700 dark:text-white">
                {localMessage}
              </h4>
            </>
          )}
          {/* <h4 className="text-base flex items-center justify-center text-navy-700 dark:text-white">
            <GenericModal
              modalTrigger={
                <button className="!linearmr-2 mt-[15px] mr-[2px] flex items-center justify-center rounded-lg  p-2  !transition !duration-200 hover:bg-gray-100 active:bg-gray-200  ">
                  <MdMessage className="mr-[10px] h-6 w-6" /> Message Regulars
                </button>
              }
              modalContent={<SendMessage />}
            />
          </h4> */}
          <h4 className="flex items-center justify-center text-base text-navy-700 dark:text-white">
            <GenericModal
              modalTrigger={
                <button className="!linearmr-2 mt-[15px] mr-[2px] flex items-center justify-center rounded-lg  p-2 ">
                  <MdPeopleAlt className="mr-[10px] h-6 w-6" /> View Regulars
                </button>
              }
              modalContent={<RegularsTable columnsData={RegularcolumnsData} />}
            />
          </h4>
        </div>
      </div>
    </div>
  );
}
