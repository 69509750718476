import React, { useEffect, useState } from "react";
import { MdOutlineClose, MdKeyboardArrowRight } from "react-icons/md";
import { getLocalStorageValue } from "utils/utils";
import UserProfile from "./UserProfile";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { toast } from "react-toastify";
import httpService from "services/http-service";
import { typeEmojis } from "utils/utils";

export default function Notifications({ ...props }) {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [matches, setMatches] = React.useState([]);
  const [chats, setChats] = React.useState([]);
  const [selectedUserId, setSelectedUserId] = React.useState(0);

  useEffect(() => {
    populateData();
  }, []);

  const populateData = async () => {
    setLoading(true);
    const userId = getLocalStorageValue("userDetails", "userId");
  
    const endpoint = `EventUserInteraction/GetMatchQueue?UserId=${userId}`;
    const response = await httpService("GET", endpoint);
    
    if (!response) {
      setLoading(false);
      toast.error('Something went wrong');
    } else {
      setLoading(false);
      setMatches(response?.matches || []);
      setChats(response?.chats || []);
  
      // Calculate hasNotifications based on whether there are matches or chats
      const hasNotifications = response.matches.length > 0 || response.chats.length > 0;
  
      // Update parent component's state
      props.setHasNotifications(hasNotifications);
    }
  };
  

  const handleOpenModal = (notification) => {
    setSelectedUserId(notification?.userId || notification?.user?.id)
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="flex h-full w-full justify-center px-2 pt-6 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      {/* Settings Menu */}
      {/* <Loading loading={loading} loaderColor="#252424" /> */}

      <div className="mt-[0vh] w-full max-w-full flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">

        <h4 className="align-center mb-[59px] mt-[5px] flex justify-center text-xl font-bold text-navy-700 dark:text-white">
          Notifications
        </h4>
        <div className="mr-11 ml-11 flex-col items-center justify-center">
          {matches.length === 0 && chats.length === 0 && (
            <p className="text-center text-gray-500 dark:text-gray-400 mt-6">
              You don’t have any notifications yet, close this page and go mingle.
            </p>
          )}
          {matches.length > 0 && matches.map((match, index) => (
            <React.Fragment key={index}>
              <button
                onClick={() => handleOpenModal(match)} // Pass the match object to the handler
                className="mt-[47px] flex w-full items-center justify-between text-base-notifications text-navy-700 dark:text-white"
              >
                <div>
                  <img src={match.photoUrl} alt={match.name} className="w-8 h-8 rounded-full mr-3" /> {/* Display the image */}
                </div>
                <span ><b>{match.name}</b> {/* Display the name */}
                  spotted you and sent you {typeEmojis[match.type]}</span>

                <MdKeyboardArrowRight className=".ml-6-notification h-6 w-6 self-center text-gray-500" />
              </button>
              {index !== matches.length - 1 && (
                <div className="mt-5 h-[1px] w-full border-t border-gray-200"></div>
              )}
            </React.Fragment>
          ))}
          <div className="mt-5 h-[1px] w-full border-t border-gray-200"></div>

          {chats && chats.length > 0 && chats.map((chat, index) => (
            <React.Fragment key={index}>
              <button
                onClick={() => handleOpenModal(chat)} // Pass the match object to the handler
                className="mt-[47px] flex w-full items-center justify-between text-base text-navy-700 dark:text-white"
              >
                <div>
                  <img src={chat.user?.photoUrl} alt={chat.user.name} className="w-8 h-8 rounded-full mr-3" /> {/* Display user's photo */}
                </div>
                <b>{chat.user?.name}</b> {/* Display the name */}

                <span>says {chat.userChat?.message}</span>

                <MdKeyboardArrowRight className="h-6 w-6 self-end text-gray-500" />
              </button>
              {index !== chats.length - 1 && (
                <div className="mt-5 h-[1px] w-full border-t border-gray-200"></div>
              )}
            </React.Fragment>
          ))}


        </div>

      </div>
        {/* Dialog to display user profile */}
        <Dialog
            open={openModal}
            onClose={handleCloseModal}
            fullScreen
          >
        <DialogContent>

            <div className="flex overflow-y-auto hide-scrollbar inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white relative"> {/* Add relative positioning */}
                {/* White background row */}
                <div className="bg-white flex justify-end sticky top-0 z-10"> {/* Add sticky positioning */}
                  <button className="p-2" onClick={handleCloseModal}>
                    <MdOutlineClose className="h-6 w-6" />
                  </button>
                </div>
                <DialogTitle>
                  <div className="flex justify-between items-center w-full">
                    <div></div>
                  </div>
                </DialogTitle>
                    <div>
                      <UserProfile selectedUserId={selectedUserId} />
                    </div>
              </div>
            </div>
            </DialogContent>
          </Dialog>
    </div>
  );
}
