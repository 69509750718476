import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Get the eventId from the URL
    const urlParams = new URLSearchParams(location.search);
    const eventId = urlParams.get('eventId');
    // Save the eventId to localStorage
    if (eventId) {
      localStorage.setItem('eventId', eventId);
      
      // Redirect to the login page
      navigate("/auth/sign-in");
    }
  }, [location]);
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="/" element={<Navigate to="/auth" replace />} />
    </Routes>
  );
};

export default App;
