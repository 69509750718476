import Card from "components/card";
import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import logo from "assets/img/logo/logo.png";
import config from "utils/config";
import { getLocalStorageKey } from "utils/utils";

export default function EventScan({ bartenderName }) {
  const [QRCodeValue, setQRCodeValue] = useState("");

  useEffect(() => {
    generateQRCode(); // Generate QR code on initial render
  }, []);

  const generateQRCode = () => {
    const eventId = getLocalStorageKey('eventId');

    setQRCodeValue(eventId);
  };

  return (
    <Card>
      <div className="mt-8 flex flex-col items-center justify-center">
        <h2 className="text-2xl font-bold text-navy-700 dark:text-white">
          {"Scan to join"}
        </h2>

        {QRCodeValue && (
          <div className="mt-4 mb-6">
            <QRCode size={200} fgColor="black" value={config?.INVITE_BASE_URL + QRCodeValue} />
          </div>
        )}
      </div>
    </Card>
  );
}
