export const getLocalStorageValue = (key, property) => {
  var localStorageItem = localStorage.getItem(key);

  // If the item does not exist in localStorage, return null
  if (!localStorageItem) {
    return null;
  }

  // Parse the localStorage item from a JSON string to a JavaScript object
  var localStorageObject = JSON.parse(localStorageItem);

  // Return the value associated with the property
  return localStorageObject[property];
};


// Custom mask generator for email
export const emailMaskGenerator = (value) => {
  // Split the email into username and domain parts
  const [username, domain] = value.split("@");
  
  // Generate mask for username part (showing first and last characters)
  const usernameMask = "*".repeat(Math.max(username.length - 2, 0)) + username.slice(-1);

  // Generate mask for domain part (showing first character of domain)
  const domainMask = "*".repeat(Math.max(domain.length - 1, 0)) + domain.slice(-1);

  // Combine username and domain masks with "@" symbol
  const maskedValue = usernameMask + "@" + domainMask;

  return {
    maskedValue,
    selectionStart: maskedValue.length,
    selectionEnd: maskedValue.length
  };
};


export const getLocalStorageKey = (key) => {
  if (typeof window !== 'undefined') {
    var localStorageItem = localStorage.getItem(key)

    // If the item does not exist in localStorage, return null
    if (!localStorageItem) {
      return null
    }

    // Parse the localStorage item from a JSON string to a JavaScript object
    var localStorageObject = JSON.parse(localStorageItem)

    // Return the value associated with the property
    return localStorageObject
  }
}

export const calculateAge = (birthdate) => {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const typeEmojis = {
  'date': '❤️',
  'Date': '❤️',  'Network': '🤝',
  'MakeFriends': '🎉'
};
export const EventUserInteractionType = {
  Date: 'Date',
  MakeFriends: 'MakeFriends',
  Network: 'Network',
  Dislike: 'Dislike'
};